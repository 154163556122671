// src/services/api/base.ts
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { getEnvVar } from "../../utils/validateEnv";
import Cookies from "js-cookie";
import { useUserStore } from "../../store/userStore";
import {
  handleError,
  showErrorToast,
  ApplicationError,
} from "../../utils/errorHandling";

export class BaseApiService {
  protected api: AxiosInstance;

  constructor() {
    const apiUrl = getEnvVar("REACT_APP_API_URL");
    const apiVersion = getEnvVar("REACT_APP_API_VERSION");

    this.api = axios.create({
      baseURL: `${apiUrl}/${apiVersion}`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      timeout: 30000, // 30 second timeout
    });

    // Add request interceptor
    this.api.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const token = Cookies.get("jwt");
        if (token && config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        // Handle request errors (e.g., no internet connection)
        const errorDetails = handleError(error);
        showErrorToast(errorDetails);
        return Promise.reject(new ApplicationError(errorDetails));
      }
    );

    // Add response interceptor
    this.api.interceptors.response.use(
      (response: AxiosResponse) => {
        const jwtToken = response.headers["set-cookie"]?.find(
          (cookie: string) => cookie.startsWith("jwt=")
        );
        if (jwtToken) {
          const tokenValue = jwtToken.split(";")[0].split("=")[1];
          Cookies.set("jwt", tokenValue, {
            path: "/",
            secure: true,
            sameSite: "strict",
          });
        }
        return response.data;
      },
      (error) => {
        const errorDetails = handleError(error);

        // Handle 401 Unauthorized errors
        if (error.response?.status === 401) {
          const isTokenExpired =
            error.response?.data?.error === "TokenExpired" ||
            error.response?.data?.message
              ?.toLowerCase()
              .includes("refresh your token");

          if (isTokenExpired) {
            this.handleSessionExpired();
            return Promise.reject(
              new ApplicationError({
                code: "TOKEN_EXPIRED",
                message: "Session expired. Please log in again.",
                status: 401,
              })
            );
          }
        }

        // Handle 500 Server errors
        if (error.response?.status >= 500) {
          this.handleServerError();
          return Promise.reject(
            new ApplicationError({
              code: "SERVER_ERROR",
              message: "A server error occurred. Please try again later.",
              status: error.response.status,
            })
          );
        }

        // Show error toast for all other errors
        showErrorToast(errorDetails);

        // Return a rejected promise with the formatted error
        return Promise.reject(new ApplicationError(errorDetails));
      }
    );
  }

  private handleSessionExpired() {
    // Clear auth state
    this.removeAuthToken();

    // Update user store
    const userStore = useUserStore.getState();
    userStore.logout();
    userStore.setError("Session expired. Please log in again.");

    // Redirect to login page
    window.location.href = "/login";
  }

  private handleServerError() {
    // Store the current URL before redirecting
    const currentPath = window.location.pathname;
    sessionStorage.setItem("errorRedirectPath", currentPath);

    // Redirect to 500 error page
    window.location.href = "/500";
  }

  protected setAuthToken(token: string) {
    Cookies.set("jwt", token, {
      path: "/",
      secure: true,
      sameSite: "strict",
    });
    this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    useUserStore.getState().setJwtToken(token);
  }

  protected removeAuthToken() {
    Cookies.remove("jwt");
    delete this.api.defaults.headers.common["Authorization"];
    const userStore = useUserStore.getState();
    userStore.logout();
  }

  // Utility method for handling file uploads
  protected async handleFileUpload(
    file: File,
    endpoint: string,
    additionalData?: Record<string, any>
  ) {
    try {
      // Validate file size (10MB limit)
      const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
      if (file.size > MAX_FILE_SIZE) {
        throw new ApplicationError({
          code: "FILE_TOO_LARGE",
          message: "File size exceeds 10MB limit",
        });
      }

      // Create form data
      const formData = new FormData();
      formData.append("file", file);

      if (additionalData) {
        Object.entries(additionalData).forEach(([key, value]) => {
          formData.append(key, value);
        });
      }

      // Make the request
      return await this.api.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      const errorDetails = handleError(error);
      showErrorToast(errorDetails);
      throw new ApplicationError(errorDetails);
    }
  }
}
